import { Credit } from './credit';

export interface Insurance {
  id: number;
  offerId: string;
  certificateId: number;
  coverage: number;
  price: number;
  credit: Credit;
  documentsUrl: string;
  createdAt: string;
  cancelledAt: string | null;
  cancellationRequestedAt?: string | null;
}

export enum InsuranceErrors {
  EUROINS_ERROR = 'org.springframework.ws.client.WebServiceIOException',
  AGE_ERROR = '{com.itfgroup.validator.age.message}',
}
