import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Credit, SerializedCredit } from '../types/credit';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CreditStatus, CreditStatusNames } from '../types/credit-status';
import { Administrator } from '../types/administrator';
import { Page } from '../types/page';
import { CreditDto, PayOutPreferenceDto } from '../types/creditDto';
import { EntityHistoryFilter } from '../types/entity-history-filter';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { Transaction } from '../types/transaction';
import { SearchOperations } from '../types/search-criterium';
import { SearchOptions, SearchOptionsDTO } from '../types/search-options';
import { ReminderService } from './reminder.service';
import { RequestProcessingStats } from '../types/request-processing-stats';
import { NotificationService } from './notification.service';
import { ScoringRequestService } from './scoring-request.service';
import { CreditScoreView } from '../types/credit-score-view';
import { NssiAutomationScore } from '@core/types/identity-reports';

@Injectable()
export class CreditService implements EntityHistoryService {
  request: RequestService;
  private creditSource: Subject<Credit> = new Subject<Credit>();
  currentCredit = this.creditSource.asObservable();

  onCreditDetailsCreditUpdate: Subject<Credit> = new Subject<Credit>();

  onCreditDataChange = new Subject<any>();
  onCreditStatusChanged = new Subject<number>();
  onCreditTabOpenedFirst: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  reloadSectedAndOpenedCreditExpansionList = new Subject<Credit>();

  constructor(
    request: RequestService,
    private reminderService: ReminderService,
    private notification: NotificationService,
    private scoring: ScoringRequestService
  ) {
    this.request = request;
  }

  getCreditList(options: SearchOptionsDTO): Observable<Page<Credit>> {
    return this.request.get(['credits'], { params: options }).pipe(
      map((creditPage: Page<SerializedCredit>): Page<Credit> => {
        const result: Page<Credit> = {
          ...creditPage,
          content: creditPage.content.map(this.deserializeCredit.bind(this)),
        };
        return result;
      })
    );
  }

  getCreditStatusList(): Observable<CreditStatus[]> {
    return this.request.get(['credits', 'statuses']);
  }

  statusChangeOptions(status): CreditStatusNames[] {
    switch (status) {
      case CreditStatusNames.NEW:
        return [CreditStatusNames.APPROVED, CreditStatusNames.REJECTED];
      case CreditStatusNames.APPROVED:
        return [CreditStatusNames.CONFIRMED, CreditStatusNames.REJECTED];
      case CreditStatusNames.CONFIRMED:
        return [CreditStatusNames.REJECTED];
      case CreditStatusNames.REJECTED:
        return [];
      case CreditStatusNames.REGULAR:
        return [CreditStatusNames.LOSS];
      case CreditStatusNames.OVERDUE:
        return [CreditStatusNames.LOSS];
      case CreditStatusNames.LOSS:
        return [];
      case CreditStatusNames.REPAID:
        return [];
      default:
        return [];
    }
  }

  getRequestCreditListWithReminders$(
    creditListOptionsDto: SearchOptionsDTO,
    currentOperator
  ): Observable<Page<Credit>> {
    const searchOptions = new SearchOptions({
      pageSize: 5000,
    });

    searchOptions.addCriterium({
      key: 'operator.id',
      operation: SearchOperations.EQUALS,
      value: currentOperator.id,
    });

    const requestCreditList$: Observable<Page<Credit>> =
      this.getCreditList(creditListOptionsDto);

    return requestCreditList$;
  }

  showCreditProgressBar(credit: Credit) {
    const statusName = credit.creditStatus.name;
    return (
      (statusName === CreditStatusNames.REGULAR ||
        statusName === CreditStatusNames.OVERDUE ||
        statusName === CreditStatusNames.REPAID) &&
      !!credit.utilizationTime
    );
  }

  showCloneAction(credit: Credit) {
    return (
      credit.creditStatus.name === CreditStatusNames.REJECTED ||
      credit.creditStatus.name === CreditStatusNames.REPAID
    );
  }

  changeCreditStatus$(creditId, statusName, statusReason?) {
    return this.request.post(['credits', creditId, 'status', statusName], {
      body: statusReason,
    });
  }

  changeCreditReason$(creditId, statusReason?) {
    return this.request.post(['credits', creditId, 'status-reason'], {
      body: statusReason,
    });
  }

  refreshCurrentCredit(creditId: any) {
    this.getCreditById(creditId).subscribe((credit: Credit) => {
      this.notification.showLocalizedSuccessMessage({
        notificationText: 'credits.creditSuccessfullyRefreshed',
      });
      this.emiitCurrentCredit(credit);
    });
  }

  emiitCurrentCredit(credit: Credit): void {
    this.creditSource.next(credit);
  }

  getCreditById(creditId: number): Observable<Credit> {
    return this.request
      .get(['credits', creditId])
      .pipe(map(this.deserializeCredit.bind(this)));
  }

  searchCredit(params) {
    return this.request.get(['credits', 'find'], {
      params: params,
    });
  }

  saveCredit(credit: CreditDto): Observable<Credit> {
    return this.request
      .post(['credits'], { body: credit })
      .pipe(map(this.deserializeCredit.bind(this)));
  }

  deleteCredit(creditId: number): Observable<any> {
    return this.request.delete(['credits', creditId]);
  }

  updateForfeitAccruals(
    creditId: number,
    forfeitAccrualsEnabled: boolean
  ): Observable<any> {
    return this.request.post(
      ['credits', creditId, 'forfeit-accruals-enabled'],
      {
        headers: { 'Content-Type': 'application/json' },
        body: forfeitAccrualsEnabled,
      }
    );
  }

  updateCreditUtilizationFee(
    creditId: number,
    utilizationFeeEnabled: boolean
  ): Observable<any> {
    return this.request.post(['credits', creditId, 'utilization-fee'], {
      headers: { 'Content-Type': 'application/json' },
      body: utilizationFeeEnabled,
    });
  }

  updateFullRateAccruals(
    creditId: number,
    fullRateAccruals: boolean
  ): Observable<any> {
    return this.request.post(['credits', creditId, 'full-rate-accruals'], {
      headers: { 'Content-Type': 'application/json' },
      body: fullRateAccruals,
    });
  }

  updateOperator(
    creditId: number,
    administrator: Administrator
  ): Observable<any> {
    return this.request.post(['credits', creditId, 'operator'], {
      body: administrator,
    });
  }

  updateRefinance(
    creditId: number,
    refinanceEnabled: boolean
  ): Observable<any> {
    return this.request.post(['credits', creditId, 'refinance-enabled'], {
      headers: { 'Content-Type': 'application/json' },
      body: refinanceEnabled,
    });
  }

  getCreditTransactions$(creditId: number | string): Observable<Transaction[]> {
    return this.request.get(['credits', creditId, 'transactions']);
  }

  updatePaymentMethod(
    creditId: number,
    payOutPreferenceDto: PayOutPreferenceDto
  ) {
    return this.request.post(['credits', creditId, 'payment-method'], {
      body: payOutPreferenceDto,
    });
  }

  setDiscountCode(creditId: number, discountCode: string) {
    return this.request.post(['credits', creditId, 'discount-code'], {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: discountCode,
    });
  }

  calculateCentrycsScore(creditId: number): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculateInstallmentsNewClientsScore(
    creditId: number
  ): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'model', 7])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculatePaydayNewClientsScore(
    creditId: number
  ): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'model', 8])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculateInternalScoringRepeatClientScore(
    creditId: number
  ): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'model', 10])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculateInstallmentsScoringRepeatClientScore(
    creditId: number
  ): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'model', 11])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculateScoreInternal(creditId: number): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'internal'])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  calculatePaydayInternalScore(creditId: number): Observable<CreditScoreView> {
    return this.scoring
      .post(['credits', creditId, 'internal-payday'])
      .pipe(map((s) => new CreditScoreView(s)));
  }

  getScoreEventLogByCredit$(creditId: number): Observable<any> {
    return this.scoring.get(['credits', creditId, 'event-log']).pipe(
      map((audits) => {
        return audits.map((s) => {
          s.data.entity = new CreditScoreView(s.data.entity);
          return s;
        });
      })
    );
  }

  getScoreEventLogByCivilId$(civilId: number): Observable<any> {
    return this.scoring.get(['users', civilId, 'event-log']).pipe(
      map((audits) => {
        return audits.map((s) => {
          s.data.entity = new CreditScoreView(s.data.entity);
          return s;
        });
      })
    );
  }

  getLatestScores(creditId: number): Observable<CreditScoreView[]> {
    return this.scoring.get(['credits', creditId, 'latest']).pipe(
      map((scores) => {
        return scores.map((s) => new CreditScoreView(s));
      })
    );
  }
  getLatestScoreByModelId(
    creditId: number,
    modelId: number
  ): Observable<NssiAutomationScore> {
    return this.scoring.get(['credits', creditId, 'latest', modelId]);
  }
  runNssiAotomationModel(creditId: number): Observable<NssiAutomationScore> {
    return this.scoring.post(['credits', creditId, 'nssi-automation']);
  }

  updateStatus(creditId: number, creditStatus: CreditStatus): Observable<any> {
    return this.request.post(['credits', creditId, 'status'], {
      body: creditStatus.id,
    });
  }

  getStatusTransitions(creditId: number): Observable<CreditStatus[]> {
    return this.request.get(['credits', creditId, 'status-transitions']);
  }

  serializeCredit(creditToSerialize: Credit): SerializedCredit {
    return {
      ...creditToSerialize,
      firstInstallmentDate:
        creditToSerialize.firstInstallmentDate.toISOString(),
    };
  }

  deserializeCredit(creditToDeserialize: SerializedCredit): Credit {
    return {
      ...creditToDeserialize,
      firstInstallmentDate:
        creditToDeserialize.firstInstallmentDate &&
        new Date(creditToDeserialize.firstInstallmentDate),
    };
  }

  getCreditHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['credits', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getCreditHistory(filter);
  }

  pullFromPendingRequests(): Observable<Credit> {
    return this.request.post(['credits', 'pending', 'pull']);
  }

  getRequestProcessingStats(
    dateFrom: string,
    dateTo: string
  ): Observable<RequestProcessingStats> {
    return this.request.get(['credits', 'processing', 'stats'], {
      params: { from: dateFrom, to: dateTo },
    });
  }

  getCreditMatchForPayin$(params): Observable<any> {
    //TODO interface
    return this.request.get(['credits', 'obligations'], {
      params: params,
    });
  }

  getInsuranceAmount(params: any): Observable<any> {
    return this.request.get(['insurances', 'credits'], {
      params: params,
    });
  }

  getProductPlanWithInsurance(
    productPlanId: number,
    birthDate: string
  ): Observable<any> {
    return this.request.get(
      ['insurances', 'credits', 'plans', productPlanId, 'insurance'],
      {
        params: { birthDate: birthDate },
      }
    );
  }

  getCreditWithInsuranceById(creditId: number): Observable<any> {
    return this.request.get(['insurances', 'credits', creditId]);
  }

  rejectInsuranceCertificateByCreditId(creditId: number): Observable<any> {
    return this.request.post([
      'insurances',
      'credits',
      creditId,
      'certificate',
      'reject',
    ]);
  }

  requestToggleInsuranceCancellation(creditId: number): Observable<any> {
    return this.request.post([
      'insurances',
      'credits',
      creditId,
      'toggle-cancellation',
    ]);
  }
}
