import { Pipe, PipeTransform } from '@angular/core';
import { Insurance } from '@app/core/types/insurance';

@Pipe({
  name: 'isInsuranceCancellable'
})
export class InsuranceCancellablePipe implements PipeTransform {
  transform(insurance: Insurance): boolean {
    if (!insurance) {
      return false;
    }

    const hasCertificate = insurance?.certificateId;
    const isNotCancelled = !insurance?.cancelledAt;
    const hasNoRepayment = !insurance?.credit?.repaymentDate;

    return hasCertificate && 
           isNotCancelled &&
           hasNoRepayment;
  }
}
