import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, Subject } from 'rxjs';
import { Page } from '../types/page';
import { Payment, PaymentSummary } from '../types/payment';
import { SearchOptionsDTO } from '../types/search-options';
import * as moment from 'moment/moment';
import { Decimal } from '../utils/decimal';

@Injectable()
export class PaymentService {
  paymentSummary$: Subject<PaymentSummary<number>> = new Subject<
    PaymentSummary<number>
  >();

  constructor(public request: RequestService) { }

  getCreditPaymentList$(
    creditId: number | string,
    options?: SearchOptionsDTO
  ): Observable<Page<Payment>> {
    return this.request.get(['credits', creditId, 'payments'], {
      params: options,
    });
  }

  getCreditPaymentById$(
    creditId: number | string,
    paymentId: number | string
  ): Observable<Payment> {
    return this.request.get(['credits', creditId, 'payments', paymentId]);
  }

  getCreditRepaiment$(creditId: number | string): Observable<any> {
    return this.request.get([
      'credits',
      creditId,
      'payments',
      'remaining',
      'repayment',
    ]);
  }

  getCreditPaymentsSummary$(
    creditId: number | string,
    date: Date = new Date()
  ): Observable<PaymentSummary<number>> {
    const deserializedDate = this.deserializeDate(date);
    return this.request.get(['credits', creditId, 'payments', 'summary'], {
      params: { date: deserializedDate },
    });
  }

  calculateSummary(summary: PaymentSummary<number>) {
    const mappedSummary: PaymentSummary<
      Decimal
    > = Decimal.mapObjectValuesToDecimal(summary);
    const {
      paidForfeit,
      paidInterest,
      paidPenalty,
      paidTax,
      paidPrincipal,
      accruedTax,
      accruedForfeit,
      accruedInterest,
      accruedPenalty,
      accruedPrincipal,
      maturedForfeit,
      maturedInterest,
      maturedPrincipal,
      income,
      repaymentFee,
      accruedUtilizationFee,
      maturedUtilizationFee,
      paidUtilizationFee,
      insuranceDiscount,
    } = mappedSummary;

    const paidOverhead: Decimal = paidForfeit
      .plus(paidInterest)
      .plus(paidPenalty)
      .plus(paidTax)
      .plus(paidUtilizationFee);

    const paidByComponent: Decimal = paidOverhead.plus(paidPrincipal);

    const accruedByComponent: Decimal = accruedTax
      .plus(accruedForfeit)
      .plus(accruedInterest)
      .plus(accruedPenalty)
      .plus(accruedPrincipal)
      .plus(accruedUtilizationFee);

    const matured: Decimal = maturedForfeit
      .plus(maturedInterest)
      .plus(maturedPrincipal)
      .plus(accruedTax)
      .plus(accruedPenalty)
      .plus(maturedUtilizationFee);

    const overpaid: Decimal = income
      .minus(paidForfeit)
      .minus(paidInterest)
      .minus(paidPenalty)
      .minus(paidPrincipal)
      .minus(paidTax)
      .minus(paidUtilizationFee);

    const overdue: Decimal = Decimal.max(
      0,
      matured.minus(paidByComponent).minus(overpaid)
    );

    const earlyRepayment: Decimal = accruedByComponent
      .minus(paidByComponent)
      .minus(overpaid)
      .minus(insuranceDiscount || new Decimal(0))
      .plus(repaymentFee);

    const earlyRepaymentWithoutFee: Decimal = accruedByComponent
      .minus(paidByComponent)
      .minus(overpaid)
      .minus(insuranceDiscount || new Decimal(0));

    const unpaidCostIncreaseWithoutFee: Decimal = Decimal.max(
      0,
      accruedByComponent
        .minus(accruedPrincipal)
        .minus(paidOverhead)
        .minus(overpaid)
    );

    const unpaidUtilizationFee: Decimal = Decimal.max(
      0,
      accruedUtilizationFee
        .minus(paidUtilizationFee)
    );

    const unpaidCostIncreaseWithoutFees: Decimal = Decimal.max(
      0,
      accruedByComponent
        .minus(accruedPrincipal)
        .minus(paidOverhead)
        .minus(overpaid)
        .minus(unpaidUtilizationFee)
    );


    const unpaidCostIncrease: Decimal = Decimal.max(
      0,
      accruedByComponent
        .minus(accruedPrincipal)
        .minus(paidOverhead)
        .minus(overpaid)
        .plus(repaymentFee)
    );

    const maturedUnpaid: Decimal = Decimal.max(
      0,
      matured
        .minus(paidByComponent)
    );

    const result = {
      overpaid,
      overdue,
      matured,
      earlyRepayment,
      unpaidCostIncrease,
      accruedByComponent,
      paidByComponent,
      repaymentFee,
      earlyRepaymentWithoutFee,
      unpaidCostIncreaseWithoutFee,
      unpaidCostIncreaseWithoutFees,
      accruedUtilizationFee,
      maturedUtilizationFee,
      paidUtilizationFee,
      maturedUnpaid,
      insuranceDiscount,
    };

    return Decimal.parseObjectValuesFromDecimal(result);
  }

  deserializeDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
